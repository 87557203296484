<template>
    <!-- 场所管理 -->
    <div class="company-admin">
        <div class="companySearch">
            <div>
                <el-button v-if="isDis" @click="addFun" class="titleButton" type="primary" icon="el-icon-plus">添加场所</el-button>
            </div>
            <div class="titleSearch">
                <!-- <el-select
                    v-if="isDis"
                    v-model="region"
                    placeholder="场所所属区域"
                    clearable
                >
                    <el-option
                        v-for="(item, index) in regions"
                        :key="index"
                        :label="item"
                        :value="index"
                    >
                    </el-option>
                </el-select> -->
                <el-select v-model="nature" placeholder="场所性质" clearable>
                    <el-option v-for="(item, index) in natures" :key="index" :label="item" :value="item">
                    </el-option>
                </el-select>
                <el-input @keyup.enter.native="searchClick" clearable v-model="input" placeholder="请输入名称/联系人/联系电话/场所地址"></el-input>
                <el-button @click="searchClick" class="titleButton" type="primary" icon="el-icon-search">搜索</el-button>
                <el-button @click="exportClick" class="titleButton" icon="el-icon-download" :loading="exportLoad">导出数据
                    <a title="data"></a>
                </el-button>
            </div>
        </div>
        <div class="companyTableC">
            <div class="companyTable">
                <el-table v-loading="loading" :data="pagingData.elements" style="width: 100%" height="100%">
                    <el-table-column prop="placeName" label="场所名称" min-width="200">
                    </el-table-column>
                    <el-table-column prop="username" label="登录账号" min-width="160">
                    </el-table-column>
                    <el-table-column prop="contact" label="联系人" min-width="100">
                    </el-table-column>
                    <el-table-column prop="contactPhone" label="联系电话" min-width="120">
                    </el-table-column>
                    <el-table-column prop="insertTime" label="注册时间" min-width="160">
                    </el-table-column>
                    <el-table-column prop="nowDoorNum" label="当前点位数量" min-width="100" align="center">
                    </el-table-column>
                    <el-table-column v-if="isDis" label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.isConfirm" @click="
                                    informationClick(scope.row, scope.$index)
                                " type="text">填报资料</el-button>
                            <el-button class="buttonC" v-if="isDis" @click="addClick(scope.row, scope.$index)" type="text">添加点位</el-button>
                            <el-dropdown v-if="isDis" placement="bottom" @command="
                                    (command) =>
                                        handleCommand(
                                            command,
                                            scope.$index,
                                            scope.row
                                        )
                                ">
                                <el-button type="text" class="el-dropdown-link">
                                    更多
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="查看点位">查看点位</el-dropdown-item>
                                    <el-dropdown-item command="重置密码">重置密码</el-dropdown-item>
                                    <el-dropdown-item command="编辑">编辑</el-dropdown-item>
                                    <!-- <el-dropdown-item command="删除">删除</el-dropdown-item> -->
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <el-pagination :current-page="currentPage" :page-size="pagingData.pageSize" @current-change="handleCurrentChange" background layout="total, prev, pager, next" :total="pagingData.totalRecords">
            </el-pagination>
        </div>
        <!-- 弹窗-添加/编辑场所 -->
        <addCompany v-if="addCompanyShow" @cancel-fun="cancelFun" @determine-fun="determineFun" :companyData="
                titlePop === '编辑场所' ? pagingData.elements[deleteIndex] : {}
            " :titlePop="titlePop"></addCompany>
        <!-- 弹窗-重置密码 -->
        <resetPaw v-if="resetPawShow" @cancelPaw-fun="cancelPawFun" @determinePaw-fun="determinePawFun" :companyName="companyName" :placeId="pagingData.elements[deleteIndex].id"></resetPaw>
        <!-- 弹窗-删除 -->
        <deletePop v-if="deletePopShow" @deletePop-fun="deletePopFun" @determineDel-fun="determineDelFun" :companyName="companyName" :name="name"></deletePop>
        <!-- 弹窗-添加/编辑点位 -->
        <addEditGate v-if="EditGateShow" @cancelGate-fun="cancelGateFun" @determineGate-fun="determineGateFun" :isCompany="true" :companyData="pagingData.elements[deleteIndex]"></addEditGate>
    </div>
</template>
<script>
    import addCompany from '../../components/addCompany'
    import resetPaw from '../../components/resetPaw'
    import deletePop from '../../components/deletePop'
    import addEditGate from '../../components/addEditGate'

    export default {
        name: 'company-admin',
        components: {
            addCompany,
            resetPaw,
            deletePop,
            addEditGate,
        },
        data() {
            return {
                loading: false,
                // 导出 loading
                exportLoad: false,
                // 权限判断
                isDis: true,
                titlePop: '编辑场所',
                // 是否显示-弹窗-编辑
                addCompanyShow: false,
                // 是否显示-弹窗-重置密码
                resetPawShow: false,
                // 是否显示-弹窗-删除
                deletePopShow: false,
                // 是否显示-弹窗-添加/编辑点位
                EditGateShow: false,
                // 场所所属区域
                region: null,
                regions: ['成都', '广元', '眉山'],
                // 场所性质
                nature: '',
                natures: [
                    '政府场所',
                    '医疗机构',
                    '教育机构',
                    '企业',
                    '景区',
                    '餐饮娱乐场所',
                    '居民社区',
                    '车站',
                    '酒店',
                    '监所',
                    '未办证留宿场所',
                    '影院',
                    '刻章',
                    '散装汽油销售',
                    '马拉松',
                    '民宿',
                    '其它',
                ],
                input: '',
                // 被选中场所名称
                companyName: '',
                name: '的场所信息',
                // 被选中场所下标
                deleteIndex: null,
                // 被选中登录账号
                userName: '',
                // 场所表格数据
                pagingData: {},
                // 分页-总条数
                totalNum: null,
                // 当前页数
                currentPage: 1,
                //列表-开始位置
                start: 1,
            }
        },
        created() {
            // 不同权限 被访问到的页面
            let userInfo = sessionStorage.getItem('userInfo') + ''
            if (userInfo === '3' || userInfo === '4') {
                this.isDis = false
            }
            // 场所列表
            this.comtanyList()
        },
        methods: {
            // 点击 更多下拉
            handleCommand(command, index, row) {
                this.deleteIndex = index
                if (command === '编辑') {
                    this.addCompanyShow = true
                    this.titlePop = '编辑场所'
                } else if (command === '删除') {
                    this.deletePopShow = true
                    this.companyName = row.placeName
                } else if (command === '查看点位') {
                    sessionStorage.setItem(
                        'seeCompanyName',
                        JSON.stringify(this.pagingData.elements[index])
                    )
                    this.$router.push('/content/gatekeeper-admin')
                }else if(command === '重置密码'){
                    this.resetPawShow = true
                    this.companyName = row.placeName
                }
            },
            // 弹窗-重置密码-点击取消
            cancelPawFun() {
                this.resetPawShow = false
            },
            // 弹窗-重置密码-点击确定
            determinePawFun() {
                // 是否显示-弹窗-重置密码
                this.resetPawShow = false
            },
            // 场所列表
            comtanyList(areaCode, placeType, key) {
                this.loading = true
                this.$instance
                    .get('/v1/place/list-page', {
                        params: {
                            pageNum: this.currentPage - this.start,
                            pageSize: 10,
                            // 区域
                            areaCode,
                            // 场所性质
                            placeType,
                            // 搜索关键字
                            key,
                        },
                    })
                    .then(res => {
                        if (res.data.status === 200) {
                            this.loading = false
                            this.pagingData = res.data.data
                        }
                    })
                    .catch(error => {
                        console.log('error: ', error)
                    })
            },
            // 点击 搜索
            searchClick() {
                // 当前页数
                this.currentPage = 1
                this.searchZhi()
            },
            // 点击导出
            exportClick() {
                // 场所类型 赋值
                let placeType = this.nature
                // 区域 赋值
                let areaCode = this.region
                // 搜索关键字 赋值
                let key = this.input
                if (this.nature) {
                    if (this.nature === '民宿') {
                        placeType = 100
                    } else if (this.nature === '其它') {
                        placeType = 99
                    } else {
                        this.natures.map((val, index) => {
                            if (this.nature === val) {
                                placeType = index + 1
                            }
                        })
                    }
                }
                if (this.region === 0) {
                    areaCode = 5101
                } else if (this.region === 1) {
                    areaCode = 5108
                } else if (this.region === 2) {
                    areaCode = 5114
                }
                this.getExport(areaCode, placeType, key)
            },
            // 导出数据
            getExport(areaCode, placeType, key) {
                // this.color()
                this.exportLoad = true
                this.$instance
                    .get('/v1/place/list/download', {
                        params: {
                            pageSize: 10000,
                            // 区域
                            areaCode,
                            // 场所类型
                            placeType,
                            // 搜索关键字
                            key,
                        },
                        responseType: 'arraybuffer',
                    })
                    .then(res => {
                        const blob = new Blob([res.data], {
                            type:
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        })
                        // IE
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(blob, 'data')
                        } else {
                            this.imatateDownloadByA(
                                window.URL.createObjectURL(blob),
                                '场所管理data'
                            )
                        }
                        this.exportLoad = false
                    })
                    .catch(error => {
                        console.log('error: ', error)
                        this.exportLoad = false
                    })
            },
            //  @param {String} href
            //  @param {String} filename
            imatateDownloadByA(href, filename) {
                const a = document.createElement('a')
                a.download = filename
                a.style.display = 'none'
                a.href = href
                // console.log('a.href: ', a.href)
                document.body.appendChild(a)
                a.click()
                a.remove()
                window.URL.revokeObjectURL(href)
            },
            // 搜索赋值
            searchZhi() {
                let areaCode = this.region
                let placeType = this.nature
                let key = this.input
                if (this.region === 0) {
                    areaCode = 5101
                } else if (this.region === 1) {
                    areaCode = 5108
                } else if (this.region === 2) {
                    areaCode = 5114
                }
                if (this.nature) {
                    if (this.nature === '民宿') {
                        placeType = 100
                    } else if (this.nature === '其它') {
                        placeType = 99
                    } else {
                        this.natures.map((val, index) => {
                            if (this.nature === val) {
                                placeType = index + 1
                            }
                        })
                    }
                }
                this.comtanyList(areaCode, placeType, key)
            },
            // 点击分页-当前页
            handleCurrentChange(val) {
                this.currentPage = val
                // 搜索并拉取列表
                this.searchZhi()
            },
            // 点击 添加场所
            addFun() {
                this.addCompanyShow = true
                this.titlePop = '添加场所'
            },
            // 弹窗-编辑-点击取消
            cancelFun() {
                this.addCompanyShow = false
            },
            // 弹窗-编辑-点击确定
            determineFun() {
                // 搜索并拉取列表
                this.searchZhi()
                // 是否显示-弹窗-编辑
                this.addCompanyShow = false
            },
            // 弹窗-删除-点击取消
            deletePopFun() {
                this.deletePopShow = false
            },
            // 弹窗-删除-点击确定
            determineDelFun() {
                this.deletePost()
            },
            // 删除场所
            deletePost() {
                this.$instance
                    .post(
                        '/v1/place/del?placeId=' +
                            this.pagingData.elements[this.deleteIndex].id,
                        {}
                    )
                    .then(res => {
                        // console.log('res: ', res.data)
                        if (res.data.status === 200) {
                            this.comtanyList()
                            this.deletePopShow = false
                            this.$message({
                                message:
                                    '删除' +
                                    this.pagingData.elements[this.deleteIndex]
                                        .placeName +
                                    '场所成功！',
                                type: 'success',
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            // 点击 填报资料
            informationClick(value, index) {
                let placeId = this.pagingData.elements[index].id
                sessionStorage.setItem('placeId', placeId)
                let title = '单位管理 / ' + value.placeName + ' - 填报资料管理'
                sessionStorage.setItem('informationTitle', title)
                this.$router.push('/content/information')
            },
            // 弹窗-添加点位-点击取消
            cancelGateFun() {
                this.EditGateShow = false
            },
            // 弹窗-添加点位-点击确定
            determineGateFun() {
                this.EditGateShow = false
            },
            // 点击 添加点位
            addClick(row, index) {
                this.deleteIndex = index
                this.companyName = row.placeName
                this.userName = row.username
                this.EditGateShow = true
            },
        },
    }
</script>
<style lang="scss" scoped>
    .company-admin {
        width: calc(100% - 40px);
        margin: 20px;
        background-color: #fff;
        // 搜索
        & ::v-deep .companySearch {
            width: 100%;
            height: 64px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 0px 20px;
            box-sizing: border-box;
            border-bottom: 1px #f0f0f0 solid;
            .el-button--primary {
                line-height: 32px;
                padding: 0 12px;
                border: unset;
            }
            .titleSearch {
                display: flex;
                flex-wrap: wrap;
                .titleSear {
                    position: relative;
                    .el-select {
                        .el-input__inner {
                            padding-right: 46px;
                        }
                        .el-input__suffix {
                            margin-right: 18px;
                            // background-color: #fff;
                            .el-icon-arrow-up {
                                display: none;
                            }
                        }
                    }
                    .iconSearch {
                        width: 24px;
                        position: absolute;
                        top: 9px;
                        right: 12px;
                        color: rgb(182, 182, 182);
                        font-size: 14px;
                        background-color: #fff;
                        text-align: center;
                        z-index: 2;
                        cursor: pointer;
                    }
                }
                .el-select > .el-input,
                .el-input {
                    margin-right: 6px;
                }
                .el-select > .el-input {
                    width: 150px;
                }
                .titleButton {
                    line-height: 30px;
                    padding: 0 12px;
                }
                .el-input {
                    width: 320px;
                    .el-input__inner {
                        height: 32px;
                        line-height: 32px;
                    }
                    .el-input__suffix {
                        i {
                            line-height: 32px;
                        }
                    }
                }
            }
        }
        .companyTableC {
            width: calc(100% - 40px);
            height: calc(100% - 144px);
            margin: 20px;
            // 表格
            & ::v-deep .companyTable {
                height: 100%;
                border: 1px #f0f0f0 solid;
                overflow: auto;
                .buttonC {
                    padding-right: 10px;
                }
                .el-table td,
                .el-table th {
                    height: 49px;
                    padding: 4px 0;
                }
            }
        }
    }
</style>

<template>
  <!-- 添加场所 -->
  <div class="addCompany">
    <div class="mask"></div>
    <div class="addCompanyCard">
      <!-- 头部 -->
      <div class="cardHeader">
        <div>{{ titlePop }}</div>
        <i class="el-icon-close" @click="cancelFun"></i>
      </div>
      <div class="cardCon">
        <div class="cardContent">
          <!-- 添加场所信息 -->
          <el-form :model="companyForm" ref="companyForm" :rules="rules">
            <el-form-item label="场所名称" prop="placeName">
              <el-input
                v-model="companyForm.placeName"
                placeholder="请输入场所名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="场所性质" prop="placeTypeC">
              <el-select
                @change="placeTypeFun"
                v-model="companyForm.placeTypeC"
                placeholder="请选择场所性质"
              >
                <el-option
                  v-for="(nature, index) in natures"
                  :key="index"
                  :label="nature"
                  :value="nature"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="addressC" label="所属区县" prop="area">
              <el-select
                class="addressProSty"
                @focus="addressProFocus"
                @change="addressProChange"
                v-model="areaProvince"
                filterable
                placeholder="省"
                :loading="ressProloading"
              >
                <el-option
                  v-for="item in ressPro"
                  :key="item.value"
                  :label="item.regionName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-select
                class="addressProSty"
                @change="addressCityChange"
                v-model="areaCity"
                filterable
                placeholder="市"
              >
                <el-option
                  v-for="item in ressCity"
                  :key="item.value"
                  :label="item.regionName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-select
                class="addressProSty addressProSty2"
                @change="addressAreaChange"
                v-model="areaArea"
                filterable
                placeholder="区"
              >
                <el-option
                  v-for="item in ressArea"
                  :key="item.value"
                  :label="item.regionName"
                  :value="item.regionName"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="场所地址" prop="address">
              <el-select
                class="addressSty"
                @hook:mounted="cancalReadOnly"
                @visible-change="cancalReadOnly"
                @keyup.enter.native="searchClick"
                :filter-method="myAddressFocus"
                ref="select"
                v-model="addressX"
                filterable
                placeholder="请输入场所地址"
                :loading="ressloading"
              >
                <el-option
                  v-for="item in ress"
                  :key="item.value"
                  :label="item.text"
                  :value="item.text"
                >
                </el-option>
              </el-select>
              <i class="el-icon-search" @click="searchClick"></i>
            </el-form-item>
            <!-- <el-form-item label="接口通道" prop="passagewayC">
                            <el-select v-model="companyForm.passagewayC" placeholder="请选择接口通道">
                                <el-option v-for="(nature, index) in passageways" :key="index" :label="nature.name" :value="nature.id"></el-option>
                            </el-select>
                        </el-form-item> -->
            <div class="isPush">
              <el-form-item label="是否内网">
                <el-radio-group v-model="companyForm.gaPushRequiredC">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="是否电信">
                <el-radio-group v-model="companyForm.pushCddxC">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <!-- <el-form-item label="手动确认">
                            <el-radio-group v-model="companyForm.isConfirmC">
                                <el-radio :label="1">需要</el-radio>
                                <el-radio :label="0">不需要</el-radio>
                            </el-radio-group>
                        </el-form-item> -->
            <el-form-item label="通行方式">
              <el-radio-group
                class="isNeedAttendanceStyle"
                v-model="companyForm.isNeedAttendanceC"
              >
                <el-radio :label="0">绿码通行</el-radio>
                <el-radio :label="1">员工通勤 + 绿码通行</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- <el-form-item v-if="companyForm.isNeedAttendanceC===2||companyForm.isNeedAttendanceC===3" label="接待对象可选项">
                            <el-radio-group v-model="companyForm.receptionOptionsC">
                                <el-radio :label="1">个人</el-radio>
                                <el-radio :label="2">单位或部门</el-radio>
                                <el-radio :label="3">个人+单位或部门</el-radio>
                            </el-radio-group>
                        </el-form-item> -->
            <!-- <el-form-item v-if="companyForm.isNeedAttendanceC===2||companyForm.isNeedAttendanceC===3" label="开通访客审核">
                            <el-radio-group v-model="companyForm.isVisitCheckC">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item> -->
            <!-- <el-form-item v-if="companyForm.isVisitCheckC===1" label="开通多级审核">
                            <el-radio-group v-model="companyForm.isLevelCheckC">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item> -->
            <!-- <el-form-item v-if="companyForm.isVisitCheckC===1" label="开通审核短信">
                            <el-radio-group v-model="companyForm.isNeedSmsC">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item> -->
            <!-- <el-form-item label="采集人脸">
                            <el-radio-group v-model="companyForm.faceC">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item> -->
            <el-form-item label="联系人" prop="contact">
              <el-input
                v-model="companyForm.contact"
                placeholder="请输入联系人"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="contactPhone">
              <el-input
                v-model="companyForm.contactPhone"
                placeholder="请输入联系电话"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="点位最大数量" prop="maxDoorNum">
                            <el-input v-model="companyForm.maxDoorNum" placeholder="请输入点位最大数量" type="number"></el-input>
                        </el-form-item> -->
            <!-- <el-form-item label="关联代理商" prop="agent">
                            <div class="titleSear">
                                <el-select class="addressSty" @hook:mounted="cancalReadOnly" @visible-change="cancalReadOnly" @keyup.enter.native="searchAgentClick" :filter-method="myAgentFocus" @blur="myAgentChange" @change="myAgentChange" ref="selectAgent" v-model="agent" filterable clearable placeholder="请输入关联代理商" :loading="ressAgentloading">
                                    <el-option v-for="item in agents" :key="item.value" :label="item.name" :value="item.name">
                                    </el-option>
                                </el-select>
                                <i class="el-icon-search iconSearch" @click="searchAgentClick"></i>
                            </div>
                        </el-form-item> -->
            <el-form-item label="场所备注">
              <el-input
                type="textarea"
                v-model="companyForm.comment"
              ></el-input>
            </el-form-item>
          </el-form>
          <!-- 开关 -->
          <!-- 是否添加点位 -->
          <div v-if="isAdd" class="switchSty">
            <span class="switchName">是否添加点位</span>
            <template>
              <el-switch
                v-model="mySwitch"
                active-color="#1890ff"
                inactive-color="rgba(0,0,0,0.25)"
              >
              </el-switch>
            </template>
          </div>
          <!-- 添加点位信息 -->
          <el-form
            v-if="isAdd && mySwitch"
            :model="myForm"
            ref="myForm"
            :rules="rules"
          >
            <el-form-item label="点位名称" prop="nameGate">
              <el-input
                v-model="myForm.nameGate"
                placeholder="请输入点位名称"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="场所码" prop="citySceneCode">
                            <el-input v-model="myForm.citySceneCode" placeholder="请输入场所码读取的内容"></el-input>
                        </el-form-item> -->
            <el-form-item label="设备SN(账号)" prop="userNameC">
              <el-select
                @keyup.enter.native="comSearchFun"
                :filter-method="myAddcomFocus"
                ref="equipment"
                v-model="myForm.userNameC"
                placeholder="请选择设备SN(账号)"
                filterable
                :loading="equipmentSnloading"
              >
                <el-option
                  v-for="(nature, index) in equipmentSns"
                  :key="index"
                  :label="nature.sn"
                  :value="nature.sn"
                ></el-option>
              </el-select>
              <i class="el-icon-search iconSearch" @click="comSearchFun"></i>
            </el-form-item>
            <el-form-item label="备注">
              <el-input type="textarea" v-model="myForm.remark"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="button">
        <el-button @click="cancelFun">取 消</el-button>
        <el-button type="primary" @click="determineFun(companyForm.placeName)"
          >确 定</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'

export default {
  name: "addCompany",
  props: {
    // 被选择场所信息
    companyData: {
      type: Object,
      default() {
        return {};
      },
    },
    // 标题
    titlePop: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 关联代理商 loading
      ressAgentloading: false,
      // 省 加载
      ressProloading: false,
      // 场所地址 加载
      ressloading: false,
      // 设备SN 加载
      equipmentSnloading: false,
      // 添加点位开关
      mySwitch: true,
      // 设备SN 选择
      equipmentSns: [],
      // 省 选中值
      areaProvince: "",
      // 市 选中值
      areaCity: "",
      // 区 选中值
      areaArea: "",
      // 场所地址 选中值
      addressX: "",
      // 是否显示 添加场所信息
      isAdd: false,
      // 关联代理商
      // agent: '',
      // agents: [],
      companyForm: {
        // 场所性质
        placeType: 1,
        // 手动确认
        isConfirm: 0,
        // maxDoorNum: 1,
      },
      // 点位表单
      myForm: {
        // 点位名称
        nameGate: "正大门",
        // 场所码
        // citySceneCode:'',
        // 设备类型
        userNameC: "",
        // 场所备注
        remark: "",
      },
      rules: {
        nameGate: [
          {
            required: true,
            message: "请输入点位名称",
            trigger: "blur",
          },
        ],
        userNameC: [
          {
            required: true,
            message: "请输入设备SN(账号)",
            trigger: "change",
          },
        ],

        placeName: [
          {
            required: true,
            message: "请输入场所名称",
            trigger: "blur",
          },
        ],
        placeTypeC: [
          {
            required: true,
            message: "请选择场所性质",
            trigger: "change",
          },
        ],
        area: [
          {
            required: true,
            message: "请选择区县",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入场所地址",
            trigger: "blur",
          },
        ],
        passagewayC: [
          {
            required: true,
            message: "请选择接口通道",
            trigger: "change",
          },
        ],
        contact: [
          {
            required: true,
            message: "请输入联系人",
            trigger: "blur",
          },
        ],
        contactPhone: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "blur",
          },
        ],
        // maxDoorNum: [
        //     {
        //         required: true,
        //         message: '请输入点位最大数量',
        //         trigger: 'blur',
        //     },
        // ],
      },
      // 场所性质-下拉选择
      natures: [
        "政府场所",
        "医疗机构",
        "教育机构",
        "企业",
        "景区",
        "餐饮娱乐场所",
        "居民社区",
        "车站",
        "酒店",
        "监所",
        "未办证留宿场所",
        "影院",
        "刻章",
        "散装汽油销售",
        "马拉松",
        "民宿",
        "其它",
      ],
      // 接口通道
      passageways: [],
      // 省
      ressPro: [],
      // 市
      ressCity: [],
      // 区
      ressArea: [],
      // 场所地址
      ress: [],
      // 被选中场所地址dzid
      dzid: "",
      // 被选中 区regionCode
      dzArea: "",
      // 添加场所访问成功得到的场所ID
      myPlaceId: "",
      // 特定人员
      isGiveAccount: null,
    };
  },
  created() {
    // 特定人员
    let isGiveAccount = sessionStorage.getItem("isGiveAccount");
    this.isGiveAccount = Number(isGiveAccount);
    // 接口通道 下拉框
    this.getChannel();
    // console.log('this.companyData: ', this.companyData)
    const companyForm = {
      ...this.companyData,
    };

    // 是否推送公安内网
    if (companyForm.gaPushRequired === 1 || this.titlePop === "添加场所") {
      companyForm.gaPushRequiredC = 1;
    } else {
      companyForm.gaPushRequiredC = 0;
    }
    // 是否推送成都电信
    if (companyForm.pushCddx === 0 || this.titlePop === "添加场所") {
      companyForm.pushCddxC = 0;
    } else {
      companyForm.pushCddxC = 1;
    }
    // 手动确认
    if (companyForm.isConfirm === 0 || this.titlePop === "添加场所") {
      companyForm.isConfirmC = 0;
    } else {
      companyForm.isConfirmC = 1;
    }
    // 采集人脸
    if (companyForm.isNeedFace === 1 || this.titlePop === "添加场所") {
      companyForm.faceC = 1;
    } else {
      companyForm.faceC = 0;
    }
    // 通行方式
    if (this.titlePop === "添加场所") {
      companyForm.isNeedAttendanceC = 1;
    } else {
      companyForm.isNeedAttendanceC = companyForm.isNeedAttendance;
    }
    // 接待对象可选项
    if (this.titlePop === "添加场所") {
      companyForm.receptionOptionsC = 1;
    } else {
      companyForm.receptionOptionsC = companyForm.receptionOptions;
    }
    // 开通访客审核
    if (this.titlePop === "添加场所") {
      companyForm.isVisitCheckC = 1;
    } else {
      companyForm.isVisitCheckC = companyForm.isVisitCheck;
    }
    // 开通多级审核
    if (companyForm.isLevelCheck === 1 || this.titlePop === "添加场所") {
      companyForm.isLevelCheckC = 1;
    } else {
      companyForm.isLevelCheckC = 2;
    }
    // 开通审核短信
    if (companyForm.isNeedSms === 1 || this.titlePop === "添加场所") {
      companyForm.isNeedSmsC = 1;
    } else {
      companyForm.isNeedSmsC = 2;
    }

    if (companyForm.placeType === 99) {
      companyForm.placeTypeC = "其它";
    } else if (companyForm.placeType === 100) {
      companyForm.placeTypeC = "民宿";
    } else {
      companyForm.placeTypeC = this.natures[companyForm.placeType - 1];
    }
    // 街道id
    if (this.titlePop === "编辑场所") {
      this.dzid = companyForm.dzid;
      this.getAList(companyForm.regionCode);
      // 场所地址-展现赋值
      this.addressX = companyForm.address;
      this.agent = companyForm.agentName;
      // this.agentId = companyForm.agentId
      companyForm.passagewayC = companyForm.channelId;
    } else {
      // companyForm.maxDoorNum = 1
    }

    this.companyForm = companyForm;
    // 是否显示添加场所信息
    if (this.titlePop === "添加场所") {
      this.isAdd = true;
    } else {
      this.isAdd = false;
    }
  },
  methods: {
    // 接口通道 下拉框
    getChannel() {
      this.$instance
        .get("/v1/channel/list", {})
        .then((res) => {
          if (res.data.status === 200) {
            this.passageways = res.data.data;
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    // 获取区县的详情
    getAList(regionCode) {
      this.$instance
        .get("/v1/address/region/" + regionCode, {})
        .then((res) => {
          if (res.data.status === 200) {
            this.areaArea = res.data.data.regionName;
            this.dzArea = res.data.data.regionCode;
            this.getFList(res.data.data.parentId);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    // 获取父级：市详情
    getFList(id) {
      this.$instance
        .get("/v1/address/id/" + id, {})
        .then((res) => {
          if (res.data.status === 200) {
            this.areaCity = res.data.data.regionName;
            this.getList(res.data.data.id, "ressArea");
            this.getFList2(res.data.data.parentId);
          }
        })
        .catch((error) => {
          console.log("err: ", error);
        });
    },
    // 获取父级：省详情
    getFList2(id) {
      this.$instance
        .get("/v1/address/id/" + id, {})
        .then((res) => {
          if (res.data.status === 200) {
            this.areaProvince = res.data.data.regionName;
            this.getList(res.data.data.id, "ressCity");
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    // iphone element搜索选择器 调出小键盘
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const Selects = this.$refs;
          // console.log(Selects) // 如果只有1个下拉框，这段就足够了---start
          if (Selects.select) {
            const input = Selects.select.$el.querySelector(".el-input__inner");
            input.removeAttribute("readonly");
          }
          // 如果有多个，下方继续添加
        }
      });
    },

    // 省-当 input 获得焦点时触发
    addressProFocus() {
      // 如果省数组没有长度
      if (this.ressPro.length === 0) {
        this.ressProloading = true;
        this.getList(0, "ressPro");
      }
    },
    // 省-选中值发生变化时触发
    addressProChange() {
      this.areaCity = "";
      this.ressCity = [];
      this.areaArea = "";
      this.ressArea = [];
      // 至空场所地址
      this.ressKong();

      this.getList(this.areaProvince, "ressCity");
    },
    // 市-选中值发生变化时触发
    addressCityChange() {
      this.areaArea = "";
      this.ressArea = [];
      // 至空场所地址
      this.ressKong();

      this.getList(this.areaCity, "ressArea");
    },
    // 区-选中值发生变化时触发
    addressAreaChange() {
      // 至空场所地址
      this.ressKong();

      this.ressArea.map((val) => {
        if (val.regionName === this.areaArea) {
          this.dzArea = val.regionCode;
        }
      });
    },
    // 至空场所地址
    ressKong() {
      this.addressX = "";
      this.ress = [];
      this.dzid = "";
    },
    // 区县列表
    getList(parentId, listKey) {
      this.$instance
        .get("/v1/address/region-list", {
          params: {
            parentId,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            this[listKey] = res.data.data;
            this.ressProloading = false;
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    // 场所地址-当 input 发生改变时
    myAddressFocus(val) {
      this.addressX = val;
    },
    // 场所地址-点击搜索图标
    searchClick() {
      this.ress = [];
      this.$refs.select.focus();
      this.ressloading = true;
      // 街道列表
      this.getRessList();
    },
    // 街道列表
    getRessList() {
      this.$instance
        .get("/v1/address/street-list", {
          params: {
            pageSize: 20,
            key: this.addressX,
            regionCode: this.dzArea,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            this.ressloading = false;
            this.ress = res.data.data.elements;
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    // 场所性质-选择发生变化时
    placeTypeFun() {
      // 场所性质-传值赋值
      if (this.companyForm.placeTypeC === "民宿") {
        this.companyForm.placeType = 100;
      } else if (this.companyForm.placeTypeC === "其它") {
        this.companyForm.placeType = 99;
      } else {
        this.natures.map((val, index) => {
          if (this.companyForm.placeTypeC === val) {
            this.companyForm.placeType = index + 1;
          }
        });
      }
    },
    // myAgentFocus(val) {
    //     this.agent = val
    // },
    // 关联代理商 选项发生改变时/光标消失时
    // myAgentChange() {
    //     if (this.agent) {
    //         // 关联代理商id
    //         this.agents.map(val => {
    //             if (this.agent === val.name) {
    //                 this.agentId = val.id
    //             }
    //         })
    //     } else {
    //         this.agentId = ''
    //     }
    // },
    // 关联代理商-点击搜索图标
    // searchAgentClick() {
    //     this.$refs.selectAgent.focus()
    //     this.ressAgentloading = true
    //     this.agentListGet()
    // },
    // // 关联代理商 列表
    // agentListGet() {
    //     this.$instance
    //         .get('/v1/agent/list', {
    //             params: {
    //                 pageSize: 20,
    //                 // 搜索关键字 SN
    //                 agentName: this.agent,
    //             },
    //         })
    //         .then(res => {
    //             if (res.data.status === 200) {
    //                 this.ressAgentloading = false
    //                 this.agents = res.data.data.elements
    //                 // 关联代理商id
    //                 this.agents.map(val => {
    //                     if (this.agent === val.name) {
    //                         this.agentId = val.id
    //                     }
    //                 })
    //             }
    //         })
    //         .catch(error => {
    //             console.log('error: ', error)
    //         })
    // },
    // 点击取消
    cancelFun() {
      this.$emit("cancel-fun");
    },
    // 点击确定
    determineFun() {
      this.ress.map((val) => {
        if (val.text === this.addressX) {
          this.dzid = val.dzid;
        }
      });
      if (
        !this.companyForm.placeName ||
        !this.companyForm.placeType ||
        !this.companyForm.contact ||
        !this.companyForm.contactPhone
      ) {
        this.$message({
          message: "必填项不能为空！",
          type: "error",
        });
        return false;
      }
      if (!this.dzid) {
        this.$message({
          message: "场所地址不能为空！",
          type: "error",
        });
        return false;
      }

      // 编辑/添加
      let value = {
        placeName: this.companyForm.placeName,
        placeType: this.companyForm.placeType,
        dzid: this.dzid,
        // 是否推送公安内网
        gaPushRequired: this.companyForm.gaPushRequiredC,
        // 是否推送成都电信
        pushCddx: this.companyForm.pushCddxC,
        // // 手动确认
        // isConfirm: this.companyForm.isConfirmC,
        // // 采集人脸
        // isNeedFace:this.companyForm.faceC,
        // // 是否需要考勤
        isNeedAttendance: this.companyForm.isNeedAttendanceC,
        // // 接待对象可选项
        // receptionOptions:(this.companyForm.isNeedAttendanceC===2||this.companyForm.isNeedAttendanceC===3)?this.companyForm.receptionOptionsC:null,
        // // 开通访客审核
        // isVisitCheck:(this.companyForm.isNeedAttendanceC===2||this.companyForm.isNeedAttendanceC===3)?this.companyForm.isVisitCheckC:null,
        // // 开通多级审核
        // isLevelCheck:this.companyForm.isVisitCheckC===1?this.companyForm.isLevelCheckC:null,
        // // 开通审核短信
        // isNeedSms:this.companyForm.isVisitCheckC===1?this.companyForm.isNeedSmsC:null,
        // 接口通道
        // channelId: this.companyForm.passagewayC,
        contact: this.companyForm.contact,
        contactPhone: this.companyForm.contactPhone,
        // 门岗数量
        // maxDoorNum: this.companyForm.maxDoorNum,
        // 关联代理商
        // agentId: this.agentId,
        comment: this.companyForm.comment,
      };
      if (this.titlePop === "编辑场所") {
        this.editPost({
          ...value,
          placeId: this.companyData.id,
        });
      } else {
        if (this.mySwitch === true) {
          if (!this.myForm.nameGate || !this.myForm.userNameC) {
            this.$message({
              message: "必填项不能为空！",
              type: "error",
            });
            return false;
          }
        }
        this.addPost(value);
      }
    },
    // 编辑场所
    editPost(value) {
      this.$instance
        .post("/v1/place/edit", value)
        .then((res) => {
          if (res.data.status === 200) {
            this.$message({
              message: this.titlePop + "成功！",
              type: "success",
            });
            this.$emit("determine-fun");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 添加场所
    addPost(value) {
      this.$instance
        .post("/v1/place/add", value)
        .then((res) => {
          if (res.data.status === 200) {
            this.myPlaceId = res.data.data;
            // 如果打开添加点位
            if (this.mySwitch === true) {
              // 添加点位接口
              this.addGatePost();
            } else {
              this.$message({
                message: this.titlePop + "成功！",
                type: "success",
              });
              this.$emit("determine-fun");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //添加点位
    addGatePost() {
      let value3 = {
        placeId: this.myPlaceId,
        doorName: this.myForm.nameGate,
        comment: this.myForm.remark,
        userName: this.myForm.userNameC,
        // 场所码
        // citySceneCode:this.myForm.citySceneCode,
      };
      this.$instance
        .post("/v1/door/add", value3)
        .then((res) => {
          if (res.data.status === 200) {
            this.$message({
              message: this.titlePop + "成功！",
              type: "success",
            });
            this.$emit("determine-fun");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 设备SN-当 input 发生改变时
    myAddcomFocus(val) {
      this.myForm.userNameC = val;
    },
    // 设备SN-点击搜索图标
    comSearchFun() {
      this.equipmentSns = [];
      // 触发select获取焦点时
      this.$refs.equipment.focus();
      this.equipmentSnloading = true;
      this.getPlace();
    },
    // 设备SN，用于下拉框
    getPlace() {
      this.$instance
        .get("/v1/device/search", {
          params: { sn: this.myForm.userNameC },
        })
        .then((res) => {
          if (res.data.status === 200) {
            this.equipmentSnloading = false;
            this.equipmentSns = res.data.data;
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.addCompany {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  .mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  // 弹窗卡片
  & ::v-deep .addCompanyCard {
    width: 780px;
    height: calc(100% - 180px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 6px;
    // 头部
    .cardHeader {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      padding: 0 40px;
      box-sizing: border-box;
      border-radius: 6px 6px 0 0;
      // 头部-icon
      .el-icon-close {
        font-size: 24px;
      }
    }
    .cardCon {
      height: calc(100% - 130px);
      .cardContent {
        height: 100%;
        overflow: auto;
        .switchSty {
          padding: 20px 130px;
          border-top: 1px #f0f0f0 solid;
          .switchName {
            margin-right: 20px;
          }
        }
        .el-form {
          padding: 30px 110px 10px;
          border-top: 1px #f0f0f0 solid;
          .el-form-item {
            margin-bottom: 20px;
            .el-form-item__label {
              width: 134px !important;
              padding-right: 20px;
              line-height: 32px;
            }
            .el-input__inner {
              line-height: 32px !important;
            }
            .el-form-item__content {
              position: relative;
              margin-left: 134px !important;
              line-height: 32px;
              .isNeedAttendanceStyle {
                margin-top: 8px;
                .el-radio {
                  margin-bottom: 16px;
                }
              }
              .el-select {
                width: 100%;
              }
              // 搜索图标
              .el-icon-search {
                width: 24px;
                height: 24px;
                position: absolute;
                top: 1px;
                right: 4px;
                color: rgb(182, 182, 182);
                font-size: 14px;
                background-color: #fff;
                text-align: center;
                z-index: 2;
                cursor: pointer;
              }
              .addressProSty {
                width: 31%;
                margin-right: 14px;
              }
              .addressProSty2 {
                margin-right: 0px;
              }
              .titleSear {
                position: relative;
                .el-select {
                  .el-input__inner {
                    padding-right: 46px;
                  }
                  .el-input__suffix {
                    margin-right: 18px;
                    // background-color: #fff;
                    .el-icon-arrow-up {
                      display: none;
                    }
                  }
                }
              }
              input {
                height: 32px;
              }
              i {
                line-height: 32px;
              }
            }
          }
          .isPush {
            display: none;
          }
        }
      }
    }
  }
  .button {
    padding: 20px 40px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px #f0f0f0 solid;
    .el-button {
      line-height: 30px;
      padding: 0 20px;
    }
  }
}
</style>

<template>
  <!-- 添加/编辑点位 -->
  <div class="addEditGate">
    <div class="mask"></div>
    <div class="addEditGateCard">
      <!-- 头部 -->
      <div class="cardHeader">
        <div>{{ catdTitle }}</div>
        <i class="el-icon-close" @click="cancelGateFun"></i>
      </div>
      <el-form :model="myForm" ref="myForm" :rules="rules">
        <el-form-item label="点位名称" prop="doorName">
          <el-input
            v-model="myForm.doorName"
            placeholder="请输入点位名称"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="isDis" label="所属场所" prop="placeName">
          <el-select
            @change="companyChange"
            @blur="companyChange"
            @keyup.enter.native="comSearchFun"
            :filter-method="myAddcomFocus"
            ref="select"
            v-model="myForm.placeName"
            placeholder="请选择所属场所"
            :disabled="isCompany"
            filterable
            :loading="companyloading"
          >
            <el-option
              v-for="(nature, index) in companys"
              :key="index"
              :label="nature.placeName"
              :value="nature.placeName"
            ></el-option>
          </el-select>
          <i class="el-icon-search iconSearch" @click="comSearchFun"></i>
        </el-form-item>
        <!-- <el-form-item label="场所码" prop="citySceneCode">
                    <el-input v-model="myForm.citySceneCode" placeholder="请输入场所码读取的内容"></el-input>
                </el-form-item> -->
        <el-form-item label="设备SN(账号)" prop="userNameC">
          <el-select
            @keyup.enter.native="snSearchFun"
            :filter-method="equipmentSnFocus"
            ref="equipment"
            v-model="myForm.userNameC"
            placeholder="请选择设备SN(账号)"
            filterable
            :loading="equipmentSnloading"
          >
            <el-option
              v-for="(nature, index) in equipmentSns"
              :key="index"
              :label="nature.sn"
              :value="nature.sn"
            ></el-option>
          </el-select>
          <i class="el-icon-search iconSearch" @click="snSearchFun"></i>
        </el-form-item>
        <!-- <el-form-item label="每秒请求限制" prop="secondLimit">
                    <el-input
                        class="limitInputSty"
                        type="number"
                        v-model="myForm.secondLimit"
                        placeholder="请输入每秒请求限制"
                    ></el-input>
                    <span class="limitSty">0为不限制</span>
                </el-form-item> -->
        <!-- <el-form-item label="每日请求限制" prop="dayLimit">
                    <el-input
                        class="limitInputSty"
                        type="number"
                        v-model="myForm.dayLimit"
                        placeholder="请输入每日请求限制"
                    ></el-input>
                    <span class="limitSty">0为不限制</span>
                </el-form-item> -->
        <el-form-item label="到期时间" prop="expireTime">
          <el-date-picker
            @change="btnuseredaoqisj"
            v-model="myForm.expireTime"
            type="date"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="开通费金额">
          <el-input
            v-model="myForm.money"
            placeholder="请输入开通费金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="myForm.comment"></el-input>
        </el-form-item>
      </el-form>
      <div class="button">
        <el-button @click="cancelGateFun">取 消</el-button>
        <el-button type="primary" @click="determineGateFun">确 定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "addEditGate",
  props: {
    // 标题
    catdTitle: {
      type: String,
      default: "添加点位",
    },
    // 所属场所-是否禁用
    isCompany: {
      type: Boolean,
      default: false,
    },
    // 场所信息
    companyData: {
      type: Object,
      default() {
        return {};
      },
    },
    // 点位ID
    doorId: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      // 加载所属场所
      companyloading: false,
      // 加载设备SN
      equipmentSnloading: false,
      // 所属场所 是否禁用
      isDis: true,
      // 表单
      myForm: {
        // 点位名称
        doorName: "",
        // 所属场所
        placeName: "",
        // 场所码
        // citySceneCode:'',
        // 设备SN
        userNameC: "",
        // 每秒请求限制
        // secondLimit: 0,
        // 每日请求限制
        // dayLimit: 0,
        // 场所备注
        comment: "",
        // 到期时间
        expireTime: "",
        // 开通经费
        money: "",
      },
      rules: {
        doorName: [
          {
            required: true,
            message: "请输入点位名称",
            trigger: "blur",
          },
        ],
        placeName: [
          {
            required: true,
            message: "请选择所属场所",
            trigger: "change",
          },
        ],
        userNameC: [
          {
            required: true,
            message: "请选择设备SN",
            trigger: "change",
          },
        ],
        expireTime: [
          {
            required: true,
            message: "到期时间必填",
            trigger: "change",
          },
        ],
        // secondLimit: [
        //     {
        //         required: true,
        //         message: '请输入每秒请求限制',
        //         trigger: 'blur',
        //     },
        // ],
        // dayLimit: [
        //     {
        //         required: true,
        //         message: '请输入每日请求限制',
        //         trigger: 'blur',
        //     },
        // ],
      },
      // 所属场所拉取到的
      companys: [],
      // 设备SN拉取到的
      equipmentSns: [],
      // 设备SN input框发生改变时
      userNameC: "",
      // 被选中的场所id
      companyId: "",
    };
  },
  created() {
    let a = sessionStorage.getItem("userInfo") + "";
    if (a === "2") {
      this.isDis = false;
    }
    // console.log(' this.companyData: ', this.companyData)
    const myForm = {
      ...this.companyData,
    };
    if (this.catdTitle === "添加点位") {
      if (this.isCompany) {
        myForm.placeName = this.companyData.placeName;
        this.companyId = myForm.id;
      } else {
        myForm.placeName = "";
      }
      myForm.secondLimit = 0;
      myForm.dayLimit = 0;
    } else if (this.catdTitle === "编辑点位") {
      this.companyId = myForm.placeId;
      myForm.userNameC = myForm.userName;
    }
    this.myForm = myForm;
  },
  methods: {
    // 到期时间监听
    btnuseredaoqisj(e) {
      console.log(e);
      this.myForm.expireTime = e;
    },
    // 所属场所-点击搜索图标
    comSearchFun() {
      this.companys = [];
      // 触发select获取焦点时
      this.$refs.select.focus();
      this.companyloading = true;
      this.getPlace();
    },
    // 所属场所-当 input 发生改变时
    myAddcomFocus(val) {
      this.myForm.company = val;
    },
    // 所属场所 改变时
    companyChange() {
      this.companys.map((val) => {
        if (this.myForm.placeName === val.placeName) {
          this.companyId = val.id;
        }
      });
    },
    // 场所列表，用于下拉框
    getPlace() {
      this.$instance
        .get("/v1/place/search", {
          params: { placeName: this.myForm.company },
        })
        .then((res) => {
          if (res.data.status === 200) {
            this.companyloading = false;
            this.companys = res.data.data;
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    // 点击取消
    cancelGateFun() {
      this.$emit("cancelGate-fun");
    },
    // 点击确定
    determineGateFun() {
      if (
        !this.myForm.doorName ||
        !this.companyId ||
        !this.myForm.userNameC ||
        !this.myForm.expireTime
      ) {
        this.$message({
          message: "必填项不能为空！",
          type: "error",
        });
        return false;
      }
      let url = "/v1/door/add";
      let url2 = "/v1/door/edit";
      // 添加
      let value = {
        doorName: this.myForm.doorName,
        placeId: this.companyId,
        userName: this.myForm.userNameC,
        // secondLimit: Number(this.myForm.secondLimit),
        // dayLimit: Number(this.myForm.dayLimit),
        comment: this.myForm.comment,
        expireTime: this.myForm.expireTime,
        money: this.myForm.money,

        // 场所码
        // citySceneCode:this.myForm.citySceneCode,
      };
      if (this.catdTitle === "添加点位") {
        this.gatePost(url, value);
      } else {
        this.gatePost(url2, { ...value, doorId: this.doorId });
      }
    },
    // 场所 添加/编辑点位
    gatePost(url, value) {
      this.$instance
        .post(url, value)
        .then((res) => {
          if (res.data.status === 200) {
            this.$emit("determineGate-fun");
            this.$message({
              message: this.catdTitle + "成功！",
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 设备SN-当 input 发生改变时
    equipmentSnFocus(val) {
      this.userNameC = val;
    },
    // 设备SN-点击搜索图标
    snSearchFun() {
      this.equipmentSns = [];
      // 触发select获取焦点时
      this.$refs.equipment.focus();
      this.equipmentSnloading = true;
      this.getEquipment();
    },
    // 设备SN，用于下拉框
    getEquipment() {
      this.$instance
        .get("/v1/device/search", {
          params: { sn: this.userNameC },
        })
        .then((res) => {
          if (res.data.status === 200) {
            this.equipmentSnloading = false;
            this.equipmentSns = res.data.data;
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.addEditGate {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  .mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  // 弹窗卡片
  & ::v-deep .addEditGateCard {
    width: 580px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 6px;
    // 头部
    .cardHeader {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      padding: 0 40px;
      box-sizing: border-box;
      border-radius: 6px 6px 0 0;
      // 头部-icon
      .el-icon-close {
        font-size: 24px;
      }
    }
    .el-form {
      padding: 30px 40px 10px;
      border-top: 1px #f0f0f0 solid;
      border-bottom: 1px #f0f0f0 solid;
      .el-form-item {
        margin-bottom: 20px;
        .el-form-item__label {
          width: 120px !important;
          padding-right: 20px;
          line-height: 32px;
        }
        .el-input__inner {
          line-height: 32px !important;
        }
        .el-form-item__content {
          margin-left: 120px !important;
          line-height: 32px;
          .limitInputSty {
            width: calc(100% - 80px);
            margin-right: 10px;
          }
          .limitSty {
            color: #666;
          }
          .el-select {
            width: 100%;
            i {
              display: none;
            }
          }
          input {
            height: 32px;
          }
          i {
            line-height: 32px;
          }
        }
        .iconSearch {
          width: 24px;
          position: absolute;
          right: 4px;
          color: rgb(151, 151, 151);
          font-size: 14px;
          text-align: center;
          z-index: 2;
          cursor: pointer;
        }
      }
    }
  }
  .button {
    padding: 20px 40px;
    display: flex;
    justify-content: flex-end;
    .el-button {
      line-height: 30px;
      padding: 0 20px;
    }
  }
}
</style>

<template>
    <!-- 重置密码 -->
    <div class="resetPaw">
        <div class="mask"></div>
        <div class="resetPawCard">
            <!-- 头部 -->
            <div class="cardHeader">
                <div>{{ catdTitle }}</div>
                <i class="el-icon-close" @click="cancelPawFun"></i>
            </div>
            <div class="carContent">
                是否将
                <span class="companySty">{{ companyName }}</span> 的密码重置为
                <span class="pawSty">{{ paw }}</span> ？
            </div>
            <div class="button">
                <el-button @click="cancelPawFun">取 消</el-button>
                <el-button type="primary" @click="determinePawFun"
                    >确 定</el-button
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'resetPaw',
    props: {
        // 单位名称
        companyName: {
            type: String,
            default: '',
        },
        // 单位id
        placeId: {
            type: String,
            default: '',
        },
        doorId: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            catdTitle: '重置密码',
            paw: 'tqb123456',
        }
    },
    created() {},
    methods: {
        // 点击取消
        cancelPawFun() {
            this.$emit('cancelPaw-fun')
        },
        // 点击确定
        determinePawFun() {
            this.$instance
                .post('/v1/place/pwd-reset?placeId=' + this.placeId, {})
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$message({
                            message:
                                '已成功重置' + this.companyName + '的密码！',
                            type: 'success',
                        })
                        this.$emit('cancelPaw-fun')
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.resetPaw {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    .mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
    }
    // 弹窗卡片
    .resetPawCard {
        width: 400px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 6px;
        // 头部
        .cardHeader {
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            padding: 0 40px;
            box-sizing: border-box;
            border-radius: 6px 6px 0 0;
            // 头部-icon
            .el-icon-close {
                font-size: 24px;
            }
        }
        .carContent {
            padding: 20px;
            text-align: center;
            border-top: 1px #f0f0f0 solid;
            .companySty {
                color: #3399ff;
            }
            .pawSty {
                color: #cd3939;
            }
        }
    }
    .button {
        padding: 20px 40px;
        display: flex;
        justify-content: flex-end;
        .el-button {
            line-height: 30px;
            padding: 0 20px;
        }
    }
}
</style>
